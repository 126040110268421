import {
	CollaboratorsNames,
	GenericsNames,
	NavigationNames,
	ScoreRangeNames,
	SurveyNames,
} from '../../translations';
import { EntityToExport, ToExcel, ToPdf } from '../../utils/exportService';
import {
	FILTER,
	GET_SURVEY_UPDATE,
	LOADING_OFF,
	LOADING_ON,
} from '../../business/constants';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
	faEye,
	faFilter,
	faPencilAlt,
	faPlusCircle,
	faTrashAlt,
	faUsersCog,
} from '@fortawesome/free-solid-svg-icons';
import {
	getTranslation,
	useCustomTranslation,
} from '../../hooks/useTranslations';

import { Column } from 'primereact/column';
import CustomDatePicker from '../../Components/Calendar/CustomDatePicker';
import { CustomIconButton } from '../../Components/Buttons/CustomIconButton';
import { DateBodyTemplate } from '../../utils/utilsService';
import { EvaluaAlertDialog } from '../../Components/Dialogs/EvaluaAlertDialog';
import { EvaluaTable } from '../../Components/Table/EvaluaTable';
import { FilterMatchMode } from 'primereact/api';
import { IsNullOrEmpty } from '../../utils';
import { LoadingContext } from '../../context/LoadingContext';
import { MultiSelectComponent } from '../../Components/Input/multiSelect';
import { NavigationContext } from '../../context/navigationContext';
import { SelectComponent } from '../../Components/Select';
import { SideContainer } from '../../Components/sideContainer';
import { StoreContext } from '../../business/Provider';
import { SurveyDialog } from '../../Components/Wizard/surveyDialog';
import { SurveyObject } from './constants/SurveyObject';
import { SurveySchema } from './constants/SurveySchema';
import { TableButton } from '../../Components/Buttons/TableButtons';
import { Toast } from '../../Components/toast';
import ToolBarThreeColumns from '../../Components/Table/ToolBarThreeColumns';
import { generalFilter } from './functions';
import useError from '../../hooks/useErrors';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import useReload from '../../hooks/useReload';
import { AddButtonComponentIcon } from '../../Components/AddButtonComponentIcon';
import { MdOutlineFilterAlt, MdOutlineFilterAltOff } from 'react-icons/md';
import * as yup from 'yup';
import useGuideSurveys from '../../hooks/surveys/useGuideSurveys';
import useQuestionnaireGuide from '../../hooks/surveys/useQuestionnaireGuide';
export const Survey = () => {
	/* Destructuring the guideId from the useParams hook. */
	let { guideId } = useParams();

	/* Using the useContext hook to get the state and dispatch functions from the StoreContext. */
	const { surveysState, dispatchSurveys, dispatchLoading } =
		useContext(StoreContext);
	const { t } = useCustomTranslation();
	const navigation = useContext(NavigationContext);
	/* Using the useError hook to check for errors in the surveysState and dispatchSurveys. */
	useError(surveysState, dispatchSurveys);
	const resultOpc = [
		{ label: 'Todos', value: 'Todos' },
		{
			label: getTranslation(t, ScoreRangeNames.GRID_COLUMN_CRITICAL),
			value: 'Muy Alto',
		},
		{
			label: getTranslation(t, ScoreRangeNames.GRID_COLUMN_HIGH),
			value: 'Alto',
		},
		{
			label: getTranslation(t, ScoreRangeNames.GRID_COLUMN_MEDIUM),
			value: 'Medio',
		},
		{
			label: getTranslation(t, ScoreRangeNames.GRID_COLUMN_SLOW),
			value: 'Bajo',
		},
		{
			label: getTranslation(t, ScoreRangeNames.GRID_COLUMN_NULL),
			value: 'Nulo',
		},
	];

	/* Using the useGuideSurveys hook to get the functions that will be used in the component. */
	const {
		postsurveys,
		putSurveys,
		deleteSurveys,
		getNextCode,
		getSurveyDetails,
		getSurvey,
	} = useGuideSurveys(guideId);
	//const { getCollaborators } = useQuestionnaireGuide(guideId);
	const [sort, setSort] = useState([{ field: '', order: -1 }]);
	const [selectedCollaborators, setSelectedCollaborators] = useState(null);
	const [collaboratorsSearch, setCollaboratorSearch] = useState([]);
	const [surveys, setSurveys] = useState([]);
	const [activeStep, setActiveStep] = useState(0);
	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
	});
	const INIT_CUSTOM_FILTERS = {
		scode: { value: null, matchMode: FilterMatchMode.CONTAINS },
		startDate: {
			constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
		},
		endDate: {
			vconstraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
		},
	};
	const [customfilters, setCustomFilters] = useState(INIT_CUSTOM_FILTERS);
	const [openConfirmation, setOpenConfirmation] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [selectedRows, setSelectedRows] = useState(null);
	const [titlePopUp, setTitlePopUp] = useState('');
	const [scodes, setScodes] = useState([]);
	const loadingObj = useContext(LoadingContext);
	useReload([getSurvey]);
	const [newSchema, setNewSchema] = useState(null);
	const [toUpdateSurvey, setToUpdateSurvey] = useState(null);
	/*  */
	useEffect(() => {
		const contain = Object.keys(surveysState.surveys).includes(guideId);
		if (surveys.length > 0 && contain) {
			setNewSchema(
				SurveySchema.concat(
					yup.object().shape({
						name: yup
							.string('')
							.required(SurveyNames.VALIDATOR_NAME_REQUIRED)
							.max(100, SurveyNames.VALIDATOR_NAME_REQUIRED_SIZE)
							.test('uniq-name', SurveyNames.UNIQ_NAME, function (value) {
								let result;
								if (toUpdateSurvey != null && value != null) {
									result = surveys.some(
										(objeto) =>
											objeto.name.trim() === value.trim() &&
											toUpdateSurvey !== objeto.id
									);
								} else if (value != null) {
									result = surveys.some(
										(objeto) => objeto.name.trim() === value.trim()
									);
								}
								return !result;
							}),
					})
				)
			);
		}
	}, [guideId, surveys, toUpdateSurvey]);
	useEffect(() => {
		const contain = Object.keys(surveysState.surveys).includes(guideId);
		if (surveysState.loading === false && contain === true)
			setSurveys(surveysState?.surveys[guideId]);
	}, [surveysState?.surveys]);

	/*  */
	useEffect(() => {
		const contain = Object.keys(surveysState.surveys).includes(guideId);
		if (surveysState.loading === false && contain === true) {
			const filterList = surveysState.filter.filterList[guideId];
			if (filterList) {
				setSurveys(filterList);
				formik.setValues(surveysState.filter.filters[guideId]);
			} else {
				setSurveys(surveysState?.surveys[guideId]);
				formik.resetForm();
			}
		}
	}, [guideId, surveysState?.surveys]);

	useEffect(() => {
		if (surveys) {
			setScodes(
				surveysState?.surveys[guideId]?.map((survey) => {
					return { value: survey.scode };
				})
			);
		}
	}, [surveys]);

	/**
	 * It closes the dialog, resets the form, and clears any errors
	 */
	const handleClose = () => {
		formik.setErrors({});
		formik.resetForm();
		setToUpdateSurvey(null);
		setOpenDialog(false);
	};

	/**
	 * ToggleModal() is a function that sets the state of openDialog to the opposite of what it currently
	 * is.
	 */
	const toggleModal = () => setOpenDialog(!openDialog);
	/**
	 * ToggleConfirmation() is a function that sets the state of openConfirmation to the opposite of what
	 * it currently is.
	 */
	const toggleConfirmation = () => setOpenConfirmation(!openConfirmation);

	/**
	 * The function is called when the user clicks the "Add Survey" button. It sets the title of the popup
	 * to "Add Survey", sets the selected collaborators to null, sets the collaborator search to an empty
	 * array, sets the active step to 0, and then calls the getNextCode function to get the next survey
	 * code. It then sets the new survey's code to the next code, and sets the formik values to the new
	 * survey
	 */
	const handleOnAdd = async () => {
		setTitlePopUp(getTranslation(t, SurveyNames.ADD_SURVEY));
		setSelectedCollaborators(null);
		setCollaboratorSearch([]);
		setActiveStep(0);
		//loading
		dispatchLoading({ type: LOADING_ON });
		const response = await getNextCode();
		const nextCode = response.data;
		const newSurvey = { ...SurveyObject };
		newSurvey.scode = nextCode;
		newSurvey.code = nextCode;
		await formik.setValues({ ...newSurvey });
		dispatchLoading({ type: LOADING_OFF });
		toggleModal();
	};

	/**
	 * This function is used to edit a survey
	 */
	const handleOnEdit = async (row) => {
		setTitlePopUp(getTranslation(t, SurveyNames.EDIT_SURVEY));
		setActiveStep(0);
		setSelectedCollaborators(null);
		setCollaboratorSearch([]);
		const response = await getSurveyDetails(row.id);
		const surveyDetails = response;
		surveyDetails.searchStartDate = null;
		surveyDetails.searchEndDate = null;
		await formik.setValues(surveyDetails);
		setToUpdateSurvey(row.id);
		toggleModal();
	};

	/**
	 * If the survey has been completed, display a warning message. Otherwise, set the searchStartDate
	 * and searchEndDate to null and toggle the confirmation modal
	 */
	const handleOnDelete = (row) => {
		if (row.completed > 0) {
			Toast('warning', getTranslation(t, SurveyNames.ERROR_DELETING_SURVEY));
		} else {
			row.searchStartDate = null;
			row.searchEndDate = null;
			formik.setValues(row);
			toggleConfirmation();
		}
	};

	/**
	 * It takes an object as an argument, sets the loading state to true, makes a post request to the
	 * server, and then sets the loading state to false
	 * @returns The response from the API call.
	 */
	const addSurvey = async (addSurveyObject) => {
		loadingObj.setLoading(true);
		const response = await postsurveys(addSurveyObject);
		if (response !== undefined) {
			getSurvey();
			toggleModal();
		} else {
			return undefined;
		}
		loadingObj.setLoading(false);
		return response.data.data.survey;
	};

	/**
	 * This function is called when the user clicks the "Update Survey" button in the modal. It takes the
	 * survey object that was passed into the modal as a prop and sends it to the backend to be updated. If
	 * the update is successful, it calls the getSurvey function to update the survey list and closes the
	 * modal
	 * @returns The updated survey object
	 */
	const updateSurvey = async (updateSurveyObject) => {
		loadingObj.setLoading(true);
		const response = await putSurveys(updateSurveyObject);
		if (response !== undefined) {
			getSurvey();
			//getCollaborators(updateSurveyObject.id);
			toggleModal();
		} else {
			return undefined;
		}
		loadingObj.setLoading(false);
		return updateSurveyObject;
	};

	/**
	 * It takes in a value, sets the loading state to true, tries to delete the survey, sets the loading
	 * state to false, and returns the response
	 * @returns The response from the deleteSurveys function.
	 */
	const handleDeleteSurvey = async (values) => {
		loadingObj.setLoading(true);
		try {
			const response = await deleteSurveys(values);
			loadingObj.setLoading(false);
			return response;
		} catch (error) {
			Toast('warning', getTranslation(t, SurveyNames.ERROR_UPDATE));
			loadingObj.setLoading(false);
			return undefined;
		}
	};

	/**
	 * It deletes a survey from the database and then removes it from the grid
	 */
	const deleteSurveyGrid = async () => {
		toggleConfirmation();
		const result = await handleDeleteSurvey(formik.values);
		if (result !== undefined) {
			//remove from grid
			let filteredCollaborator = surveys.filter(
				(_survey) => _survey.id !== formik.values.id
			);
			filteredCollaborator = _.orderBy(
				filteredCollaborator,
				['code'],
				['desc']
			);
			setSurveys(filteredCollaborator);
			const payload = {
				guideId: guideId,
				data: filteredCollaborator,
			};
			dispatchSurveys({ type: GET_SURVEY_UPDATE, payload: payload });
		}
	};

	/**
	 * `onGlobalFilterChange` is a function that takes an event as an argument, and then sets the global
	 * filter value to the value of the event target
	 */
	const onGlobalFilterChange = (e) => {
		const { value } = e.target;
		const _filters = { ...filters };
		_filters.global.value = value;
		setFilters(_filters);
		setGlobalFilterValue(value);
	};

	/**
	 * It's a function that takes in a formik form and returns a function that takes in the form's values
	 * and formik's form methods
	 */
	const addOrUpdateSurvey = async (
		values,
		{ resetForm, _setErrors, setStatus, setSubmitting }
	) => {
		loadingObj.setLoading(true);
		try {
			let response;
			const isNew = IsNullOrEmpty(values.id);
			//delete repeated values from code and mach scode and code
			values.code = Number(values.scode);
			values.conclusion !== null || ''
				? (values.conclusion = values.conclusion.replace(/\r?\n|\r/g, ' '))
				: (values.conclusion = values.conclusion = '');
			if (isNew) {
				response = await addSurvey(values);
			} else {
				response = await updateSurvey(values);
			}
			if (response !== undefined) {
				resetForm();
				setStatus({ success: true });
				setSubmitting(false);
			}
			loadingObj.setLoading(false);
		} catch (err) {
			console.error(err);
			setStatus({ success: false });
			setSubmitting(false);
			loadingObj.setLoading(false);
		}
	};
	/* *|CURSOR_MARCADOR|* */
	const formik = useFormik({
		initialValues: { ...SurveyObject },
		validationSchema: newSchema,
		onSubmit: addOrUpdateSurvey,
	});

	const configureGeneralFilter = () => {
		let { scodeSelect, searchStartDate, searchEndDate } = formik.values;
		let filterCopy = customfilters;
		filterCopy.scode.value = scodeSelect;
		filterCopy.startDate.value = searchStartDate;
		filterCopy.endDate.value = searchEndDate;
		setCustomFilters(filterCopy);
		generalFilter(formik, guideId, setSurveys, dispatchSurveys, surveysState);
	};

	const renderFilters = () => {
		return (
			<>
				<MultiSelectComponent
					options={scodes}
					value={formik.values.scodeSelect}
					optionLabel='value'
					optionValue='value'
					name='scodeSelect'
					onChange={formik.handleChange}
					title={getTranslation(t, SurveyNames.NUMBER_SURVEY)}
					showClear={false}
					filter
				/>
				<div className='flex w-full h-full border flex-col p-2'>
					<p className='truncate text-xs font-semibold'>
						{getTranslation(t, SurveyNames.LEGEND_DATE)}
					</p>
					<CustomDatePicker
						formik={formik}
						onChange={formik.handleChange}
						label={getTranslation(t, SurveyNames.INITIAL_DATE)}
						name={'searchStartDate'}
						showIcon
						labelClassName='text-small-font-size font-semibold font-input-font-family'
						maxDate={formik.values.searchEndDate}
						//size={12}
					/>
					<CustomDatePicker
						formik={formik}
						onChange={formik.handleChange}
						label={getTranslation(t, SurveyNames.FINAL_DATE)}
						name={'searchEndDate'}
						showIcon
						labelClassName='text-small-font-size font-semibold font-input-font-family'
						minDateField={formik.values.searchStartDate}
						//size={12}
					/>
					{guideId === '1' ||
					guideId === '5' ||
					guideId === '6' ||
					guideId === '7' ? null : (
						<SelectComponent
							labelClass='text-small-font-size font-semibold font-input-font-family'
							className='w-full'
							ValuesOptions={resultOpc}
							valueSelected={formik.values.result}
							name='result'
							handleChange={formik.handleChange}
							labelText={getTranslation(t, SurveyNames.RESULT)}
							optionLabel={'label'}
							optionValue={'value'}
						/>
					)}
					<div className='flex justify-center mt-5'>
						<CustomIconButton
							toolTip={getTranslation(t, GenericsNames.FILTER)}
							//icon={faFilter}
							className='!m-1'
							onClick={() => {
								configureGeneralFilter();
							}}>
							<MdOutlineFilterAlt className='text-xl' />
						</CustomIconButton>
						<CustomIconButton
							//icon={faFilterSlash}
							className='!m-1'
							toolTip='Limpiar Filtros'
							onClick={() => {
								formik.resetForm();
								const payload = {
									guideId: guideId,
									filters: {},
									filterList: null,
								};
								setCustomFilters(INIT_CUSTOM_FILTERS);
								dispatchSurveys({ type: FILTER, payload: payload });
								setSurveys(surveysState.surveys[guideId]);
							}}>
							<MdOutlineFilterAltOff className='text-xl' />
						</CustomIconButton>
					</div>
				</div>
			</>
		);
	};

	/**
	 * It takes a type as a parameter, checks if the surveys array has more than 0 elements, creates a
	 * printObj object, adds the guideId to the printObj object, adds the sort array to the printObj
	 * object, and then checks if the type is equal to 1, and if it is, it calls the ToExcel function with
	 * the printObj object, the EntityToExport.GUIDE_SURVEYS constant, and the dispatchLoading function as
	 * parameters, and if it isn't, it calls the ToPdf function with the printObj object, the
	 * EntityToExport.GUIDE_SURVEYS constant, and the dispatchLoading function as parameters
	 */
	const handleExport = async (type) => {
		if (surveys.length > 0) {
			const printObj = { ...filters, sort: sort[0], ...customfilters, guideId };
			// add guideId to printObj
			printObj.questionaryId = { value: guideId };
			printObj.sort = sort[0];
			//exportToExcel(printObj)
			const guideEntity =
				guideId === 1 || guideId === '1'
					? EntityToExport.GUIDE_SURVEYS_GUIDE_ONE
					: EntityToExport.GUIDE_SURVEYS;

			if (type === 1) {
				await ToExcel(printObj, guideEntity, dispatchLoading);
			} else {
				await ToPdf(printObj, guideEntity, dispatchLoading);
			}
		} else {
			Toast('warning', getTranslation(t, GenericsNames.NO_EXPORT_DATA));
		}
	};

	const renderHeader = () => (
		<ToolBarThreeColumns
			value={globalFilterValue}
			setValue={setGlobalFilterValue}
			setFilters={setFilters}
			onChange={onGlobalFilterChange}
			formik={formik}
			placeholder={getTranslation(t, GenericsNames.SEARCH)}
			onExcelClick={() => {
				handleExport(1);
			}}
			onPDFClick={() => {
				handleExport(2);
			}}
			onPrintClick={() => {
				const printObj = {
					exportableColumns: exportColumns,
					rows: surveys,
					title: getTranslation(t, SurveyNames.SURVEY),
					subTitle: '',
				};
				printGrid(printObj);
			}}
			rightChildren={
				<AddButtonComponentIcon
					className='!m-1'
					toolTip={getTranslation(t, GenericsNames.ADD)}
					onClick={() => handleOnAdd()}
					icon={faPlusCircle}
				/>
			}
		/>
	);

	/**
	 * It's a function that receives a rowData object as a parameter and returns a function that navigates
	 * to the /guide-{guideId}/surveysCollaborators/{guideId} route, passing the rowData object as a
	 * parameter
	 */
	const goToRanges = (rowData) => {
		navigation.selectTap(
			`/guide-${guideId}/surveysCollaborators/${guideId}`,
			{
				clear: false,
				surveyCode: rowData.scode,
				id: rowData.id,
				instanceId: rowData.instanceId,
				name: rowData.name,
				startDate: rowData.startDate,
				endDate: rowData.endDate,
				guideName: rowData.guideName,
				conclusion: rowData?.conclusion,
			},
			'Cuestionarios'
		);
	};

	/**
	 * It takes a rowData object as an argument, and then uses the rowData.id property to navigate to a
	 * new page
	 */
	const goToResults = (rowData) => {
		const title = `${getTranslation(t, NavigationNames.RESULTS)}`;
		navigation.selectTap(
			`/guide-${guideId}/results/${guideId}`,
			{
				surveyId: rowData.id,
			},
			title
		);
	};

	const renderTableButton = (icon, toolTip, action) => {
		return <TableButton icon={icon} toolTip={toolTip} onClick={action} />;
	};

	const actionBodyTemplate = (rowData) => {
		return (
			<>
				{/*guia 1 no tiene reporte de resultados, ni la guía 5, ni las guías de la NOM 037 */}
				{guideId > 1 &&
					guideId != 5 &&
					guideId != 6 &&
					renderTableButton(faEye, getTranslation(t, GenericsNames.VIEW), () =>
						goToResults(rowData)
					)}
				{renderTableButton(faUsersCog, 'Ver Colaboradores', () =>
					goToRanges(rowData)
				)}
				{renderTableButton(
					faPencilAlt,
					getTranslation(t, GenericsNames.EDIT),
					() => handleOnEdit(rowData)
				)}
				{renderTableButton(
					faTrashAlt,
					getTranslation(t, GenericsNames.DELETE),
					() => handleOnDelete(rowData)
				)}
			</>
		);
	};

	const renderDataTable = () => {
		return (
			<EvaluaTable
				value={surveys}
				loading={surveysState.loading}
				header={renderHeader()}
				selection={selectedRows}
				onSelectionChange={(e) => setSelectedRows(e.value)}
				onRowDoubleClick={(e) => {
					goToRanges(e.data);
				}}
				filters={filters}
				responsiveLayout='scroll'
				emptyMessage={getTranslation(t, SurveyNames.EMPTY_SURVEY)}
				currentPageReportTemplate={getTranslation(
					t,
					CollaboratorsNames.GRID_PAGE_REPORT_TEMPLATE
				)}
				style={{ height: 'calc(100vh - 106px)', width: 'calc(100vw - 332px)' }}
				setSort={setSort}
				sort={sort}>
				<Column
					headerClassName='hover:!text-success !border-border !border-r !text-small-font-size'
					style={{ flexGrow: 1, flexBasis: '100px' }}
					field='scode'
					header={getTranslation(t, SurveyNames.NUMBER_SURVEY)}
					sortable
				/>
				<Column
					headerClassName='hover:!text-success !border-border !border-r'
					style={{ flexGrow: 1, flexBasis: '100px' }}
					field='name'
					body={(e) => <div> {`${e.name}`}</div>}
					header={getTranslation(t, SurveyNames.NAME)}
					sortable
				/>
				<Column
					headerClassName='hover:!text-success !border-border !border-r'
					style={{ flexGrow: 1, flexBasis: '100px' }}
					field='startDate'
					header={getTranslation(t, SurveyNames.START_DATE)}
					sortable
					align='center'
					body={DateBodyTemplate}
				/>
				<Column
					headerClassName='hover:!text-success !border-border !border-r'
					style={{ flexGrow: 1, flexBasis: '100px' }}
					field='endDate'
					header={getTranslation(t, SurveyNames.END_DATE)}
					sortable
					align='center'
					body={DateBodyTemplate}
				/>
				<Column
					headerClassName='hover:!text-success !border-border !border-r !break-words'
					style={{ flexGrow: 1, flexBasis: '100px' }}
					field='totalCollaborator'
					header={getTranslation(t, SurveyNames.TOTAL_COLLABORATORS)}
					sortable
					align='center'
				/>
				<Column
					headerClassName='hover:!text-success !border-border !border-r'
					style={{ flexGrow: 1, flexBasis: '100px' }}
					field='toBeSend'
					header={getTranslation(t, SurveyNames.TO_SEND)}
					sortable
					align='center'
				/>
				<Column
					headerClassName='hover:!text-success !border-border !border-r'
					style={{ flexGrow: 1, flexBasis: '100px' }}
					field='sents'
					header={getTranslation(t, SurveyNames.SEND)}
					sortable
					align='center'
				/>
				<Column
					headerClassName='hover:!text-success !border-border !border-r'
					style={{ flexGrow: 1, flexBasis: '100px' }}
					field='completed'
					header={getTranslation(t, SurveyNames.COMPLETED)}
					sortable
					align='center'
				/>
				<Column
					headerClassName='hover:!text-success !border-border !border-r'
					style={{ flexGrow: 1, flexBasis: '100px' }}
					field='sentsWaitingAnswer'
					header={getTranslation(t, SurveyNames.SEND_FOR_REPLY)}
					sortable
					align='center'
				/>
				{(guideId == 2 || guideId == 3) && ( //La guía 1 no tiene resultados al momento, ni la guía 5
						<Column
							headerClassName='hover:!text-success !border-border !border-r'
							style={{ flexGrow: 1, flexBasis: '100px' }}
							field='currentResult'
							header={getTranslation(t, SurveyNames.CURRENT_RESULT)}
							sortable
							align='center'
						/>
					)}
				<Column
					style={{ flexGrow: 1, flexBasis: '100px' }}
					header={getTranslation(t, SurveyNames.GRID_ACTION)} //Para eliminar encuesta
					headerStyle={{ width: '4rem', textAlign: 'center' }}
					bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
					body={actionBodyTemplate}
				/>
			</EvaluaTable>
		);
	};
	return (
		<>
			<div className='flex relative'>
				<SideContainer
					className='w-[240px]'
					title={getTranslation(t, GenericsNames.FILTER)}>
					{renderFilters()}
				</SideContainer>
				{renderDataTable()}
			</div>
			{openDialog ? (
				<SurveyDialog
					title={titlePopUp}
					formik={formik}
					open={openDialog}
					onClose={handleClose}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					selectedCollaborators={selectedCollaborators}
					setSelectedCollaborators={setSelectedCollaborators}
					collaboratorsSearh={collaboratorsSearch}
					setColloboratorSearch={setCollaboratorSearch}
				/>
			) : null}
			<EvaluaAlertDialog
				title={getTranslation(t, GenericsNames.CONFIRM)}
				setOpen={toggleConfirmation}
				open={openConfirmation}
				message={getTranslation(t, SurveyNames.CONFIRM_MESSAGE_DELETE)}
				cancelLabel={getTranslation(t, GenericsNames.CANCEL)}
				agreeLabel={getTranslation(t, GenericsNames.ACCEPT)}
				onConfirmFunction={deleteSurveyGrid}
			/>
		</>
	);
};
